.fillterGridLeft {
  margin: "1rem 0";
  max-width: "100%";
  display: flex;
  align-items: center;
  justify-content: left;
}
.fillterGrid {
  margin: "1rem 0";
  max-width: "100%";
  display: flex;
  align-items: center;
  justify-content: center;
}
.fillterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 16px;
  // border: 1px solid black;
  // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, #133337 0px 4px 6px -1px,
  // rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  box-shadow: rgba(19, 51, 55, 0.5) 0px 1px 3px 0px,
    rgba(19, 51, 55, 0.5) 0px 0px 0px 1px;
  // background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  // color: rgba(19, 51, 55, 1);
  color: #5db438;
  input {
    // background: rgba(0, 0, 0, 0.01);
    // padding: 5px 0;
    // border-radius: 5px;
    // border: 1px solid #133337;
  }
  // background-color: rgb(235, 235, 235);
}

.statCards {
  margin: 5px;
  max-width: 100%;
  width: 100%;
  // display: flex;
  display: grid;
  // grid-template-columns: repeat(5, 1fr);
  // justify-content: space-between;
  gap: 10px;
  // color: #858585;

  .cards.net {
    cursor: pointer;
  }
  .cards.net:hover {
    // background-color: #133337;
    color: white;
  }

  .cards {
    width: 100%;
    // display: none;
  }
}

/* Default layout for wider screens */
@media screen and (min-width: 801px) {
  .statCards {
    grid-template-columns: repeat(5, 1fr);
    justify-content: space-between;
  }
}

/* Layout for screens between 401px and 800px */
@media screen and (max-width: 800px) {
  .statCards {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  /* Make the last item span two columns and full width */
  .cards:last-child {
    grid-column: span 2;
  }
}

/* Layout for screens below 400px */
@media screen and (max-width: 400px) {
  .statCards {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
  }
  .cards {
    grid-column: 1;
  }
}
